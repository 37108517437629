// @name Header
// @description All header styles

header {
    border-bottom: 1px solid #edefee;
    padding: ($spacer * 2) 0;
    margin-bottom: ($spacer * 2);

    @media(min-width: 640px) {
        padding: ($spacer * 10) 0 ($spacer * 3);
        margin-bottom: ($spacer * 3);
    }
}

.intro {
    margin-bottom: ($spacer * 2);

    &__pre, &__post {
        text-transform: uppercase;
        letter-spacing: 4px;
    }

    &__main {
        font-size: 1.8rem;
        line-height: 1.2;
        font-weight: bold;
        margin: 10px 0 20px;

        @media(min-width: 640px) {
            font-size: 2.2rem;
            max-width: 60%;
        }
    }
}

.social {
    a {
        margin-right: $spacer;
    }
}
