// @name Footer
// @description All footer styles

.footer {
    padding: ($spacer * 2) $spacer;
    text-align: center;
    border-top: 1px solid #edefee;

    &__title {
        font-size: 1.2rem;
        font-weight: bold;
    }

    &__subtitle {
        text-transform: capitalize;
    }
}
